<template>
  <div>
    <div class="d-flex border">
      <b-form-select
        v-model="selected"
        :options="providers"
        @change="onProviderChange()"
      ></b-form-select>
    </div>

    <div class="mt-2">
      <div class="d-flex">
        <!-- radio button -->
        <b-form-group>
          <b-form-radio-group
            size="sm"
            id="btn-radios-1"
            v-model="selectedRadio"
            button-variant="outline-primary"
            :options="optionsRadio"
            buttons
            name="radios-btn-default"
            @change="getSlotsGames()"
          />
        </b-form-group>
      </div>
    </div>

    <b-container class="bv-example-row px-1">
      <b-row class="justify-content">
        <b-col
          v-for="(list, index) in lists"
          :key="index"
          cols="4"
          sm="3"
          class="item-box"
        >
          <GameItem
            :provider="selected"
            :gameId="list.id"
            :img="list.image.vertical"
            :providerName="list.gameName"
            :rtp="list.rtp"
            :onGameSelected="handleGameSelected"
          />
        </b-col> </b-row
    ></b-container>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BFormRadioGroup,
  BFormGroup,
  BFormSelect
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import GameItem from './component/GameItem.vue'

import { getUserData } from '../auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BFormRadioGroup,
    BFormGroup,
    GameItem,
    BFormSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      selectedRadio: 'ALL',
      optionsRadio: [
        { text: 'ทั้งหมด', value: 'ALL' },
        { text: 'ซื้อสปิน', value: 'BUY_FREE_SPINS' }
      ],
      lists: [],
      user: null,
      providers: [],
      selected: '',
      gameTypes: [],
      options: [
        { value: 'pg', text: 'PG Soft' },
        { value: 'slotxo', text: 'SlotXo | Joker' },
        { value: 'spg', text: 'Spade Gaming' },
        { value: 'pragmaticslot', text: 'Pragmatic Play' },
        { value: 'microgame', text: 'Micro Gaming' },
        { value: 'ambslot', text: 'AMB Slot' },
        { value: 'jili', text: 'Jili' },
        { value: 'relaxgame', text: 'Relax Gaming' },
        { value: 'allwayspin', text: 'AllWaySpin' },
        { value: 'booongo', text: 'Booongo' },
        { value: 'simpleplay', text: 'SimplePlay' },
        { value: 'yggdrasil', text: 'Yggdrasil' },
        { value: 'wazdandirect', text: 'Wazdan Direct' },
        { value: 'live22', text: 'Live22' },
        { value: 'askmebetslot', text: 'Askmebet' },
        { value: 'ameba', text: 'Ameba' },
        { value: 'ganapati', text: 'Gamatron' },
        { value: 'kagaming', text: 'KA Gaming' },
        { value: 'funtagaming', text: 'Funta Gaming' },
        { value: 'evoplay', text: 'Evo Play' }
      ]
    }
  },
  async beforeMount() {
    this.user = getUserData()
    await this.getSlotsGames()
    await this.getProviders()
  },

  methods: {
    onProviderChange() {
      console.log(this.selected)
      window.location.href = `slots?providor=${this.selected}`
    },
    onGameSelected(game) {
      console.log(game)
      window.open(`login/slot?p=${this.selected}&g=${game.id}&t=Slot`, '_blank')
    },
    handleGameSelected(provider, gameId) {
      window.open(`login/slot?p=${provider}&g=${gameId}&t=Slot`, '_blank')
    },
    async getSlotsGames() {
      const casino = this.$route.query.providor
      console.log('a', casino)
      this.selected = casino
      const lists = await this.$http.post(`member/game/lists`, {
        provider: casino,
        tab: 'Slot'
      })

      if (this.user.agent_username === '5kdb12441') {
        const tmp = lists.data.url.data.lists
        this.lists = tmp.filter(
          list => list.gameId !== '98' && list.gameId !== 'S-LB01'
        )
      } else {
        console.log(this.selectedRadio)

        if (this.selectedRadio === 'BUY_FREE_SPINS') {
          const spinGames = lists.data.result.filter(game =>
            game.gameType.includes('BUY_FREE_SPINS')
          )
          this.lists = spinGames
        } else {
          this.lists = lists.data.result
        }
      }
    },
    async getProviders() {
      const lists = await this.$http.get(`member/slot/provider`)
      console.log('LISTS', lists.data.lists)
      lists.data.lists.forEach(element => {
        const d = {
          value: element.provider,
          text: element.providerName
        }
        this.providers.push(d)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.game-block:hover {
  .play-button {
    display: block;
  }
}

.play-button {
  display: none;
}

.type-block {
  padding: 4px;
}

.item-box {
  padding: 5px !important;
}
</style>
