<template>
  <div class="cover-box">
    <a @click="onGameSelected(provider, gameId)">
      <b-img class="rounded" fluid :src="img"></b-img>
      <span class="provider-name">{{ providerName }}</span>
      <span class="rtp-text" v-show="rtp > 0"
        ><b-badge variant="dark"> RTP: {{ formatNumber(rtp) }} </b-badge></span
      >
    </a>
  </div>
</template>

<script>
import { BImg, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BBadge
  },
  props: {
    provider: {
      required: true,
      type: [String]
    },
    gameId: {
      required: true,
      type: [String]
    },
    img: {
      required: true,
      type: [String]
    },
    providerName: {
      required: true,
      type: [String]
    },
    onGameSelected: {
      type: Function,
      required: true
    },
    rtp: {
      required: false,
      type: [Number],
      default: 0
    }
  },
  methods: {
    formatNumber(num) {
      return num.toFixed(3) // Ensures 4 decimal places
    }
  }
}
</script>
<style scoped>
.cover-box {
  display: block;
  border: 1.5px solid #beadfa;
  padding: 10px;
  margin-top: 15px;
  border-radius: 15px;
  background: linear-gradient(
    to top,
    #beadfa,
    #fff
  ); /* Gradient from top-left to bottom-left */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.provider-name {
  display: block;
  padding: 5px;
  border: 1px dashed #ddd;
  margin-top: 5px;
  border-radius: 10px;
  font-weight: bold;
  color: #7259cc;
  text-shadow: 1px 1px 0 #fff, /* Top-left shadow */ -1px -1px 0 #fff,
    /* Bottom-right shadow */ 1px -1px 0 #fff,
    /* Top-right shadow */ -1px 1px 0 #fff; /* Bottom-left shadow */
}

.rtp-text {
  position: absolute;
  top: 36px;
  right: 18px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 14px;
}
</style>
